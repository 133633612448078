<template>
    <!-- 发展规划内容 -->
  <div class="develop">
      <!-- 发展规划内容 -->
       <Newslist :list="developlist" :nodename="'xxgk_fzgh'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
  name: 'Develop',
   props: {
    developlist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  methods : {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.develop {
  width: 908px;
  padding: 39px 40px 41px 40px;
}
</style>
