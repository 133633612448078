<template>
  <!-- 捐款公示 -->
  <div class="donation">
   <div class="donateInfo">
    <!-- <TitleMore
      :title="['捐款信息','捐物信息','其他捐赠信息']"
      :isMore="false"
      @handleTab="handleTab"
    ></TitleMore> -->
    <!-- <div class="baseCard donateTop"> -->
      <!-- <el-form
        v-show="activeIndex==0"
        label-width="100px"
        label-position="right"
        class="donateForm"
      >
        <el-form-item
          label="捐赠人名称"
          style="width:100%"
        >
          <el-input
            style="width:100%"
            v-model="donateName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="捐赠金额">
          <el-input
            style="width:46%"
            v-model="minAmount"
            placeholder="请输入"
          ></el-input>
          <p style="text-align:center;width:10%">至</p>
          <el-input
            style="width:46%"
            v-model="maxAmount"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="捐赠日期">
          <el-date-picker
            style="width:46%"
            v-model="startDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <p style="text-align:center;width:10%">至</p>
          <el-date-picker
            style="width:46%"
            v-model="endDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="getdonateMsg"
          >立即查询</el-button>
        </el-form-item>
      </el-form>
      <el-form
        v-show="activeIndex!=0"
        label-width="100px"
        label-position="right"
        class="donateForm"
      >
        <el-form-item
          label="捐赠人名称"
          style="width:100%"
        >
          <el-input
            style="width:100%"
            v-model="title"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="onOtherSearch"
          >立即查询</el-button>
        </el-form-item>
      </el-form> -->
      <!-- <div class="donateIntro">
        <p style="
font-size: 18px;
font-weight: bold;
color: #333333;
margin:10px 0px">查询说明</p>
        <p style="
font-size: 16px;
color: #666666;
line-height: 28px;">如果您在捐赠查询中未查到自己的捐赠信息，可能存在以下情况：
          1、输入的查询条件是否准确；
          2、收款单位是否为中国红十字会总会。如果收款单位为中国红十字基金会、
          地方红十字会，请您联系相应的收款单位查询；
          3、如果仍未查到捐款信息，您可通过电话028-84350528（工作日）进行查询；
          4、本平台可查询2010年1月11日以来向中国红十字会总会捐赠信息；
          感谢您的爱心捐赠！
        </p>
      </div> -->
    <!-- </div> -->
    <div class="baseCard donateBottom">
      <!-- <p style="
font-size: 24px;
font-weight: bold;
color: #555454;
line-height: 48px;
text-align:center">四川省红十字会{{`${activeIndex==0?'资金':activeIndex==1?'物资':'服务'}`}}接受公示表</p>
      <TitleLine width="350px"></TitleLine> -->
      <el-table
        v-if="activeIndex==0"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          style="background:red"
          prop="donator_name"
          label="姓名"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="amount"
          label="金额(元)"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="create_date"
          label="时间"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="donation_intention"
          label="捐赠方式"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="donation_intention"
          label="捐赠类别"
          width="180"
          align="center"
        > -->
        <el-table-column
          prop="donation_message"
          label="备注"
          width="180"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div
        class="donateInfo"
        v-if="activeIndex!=0"
      >
        <div
          class="itemText"
          v-for="(item,index) in listData"
          :key="index"
        >
          <p @click="$goto('/detailShow',{id:item.id})">
            <img
              src="../../../assets/img/list-little.png"
              alt=""
            >
            <span class="itemTextContent">{{item.title}}</span>
          </p>
          <p class="itemTextTime">{{item.publishDate}}</p>
        </div>
      </div>
      <el-pagination
        style="margin-top:30px"
        :current-page="page"
        :page-size="size"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore.vue";
import TitleLine from "@components/TitleLine.vue";
import apiUrls from "@api";
export default {
  name: 'Donation',
  data() {
    return {
      activeIndex: 0,
      donateName: "",
      minAmount: "",
      maxAmount: "",
      startDate: "",
      endDate: "",
      dateValue: "",
      tableData: [],
      page: 1,
      size: 15,
      total: 0,
      title: "",
      listData: [],
      type: "",
    }
  },
  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getdonateMsg();
    // this.getDonateList(this.$route.query.type)
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getdonateMsg();
    },
    onOtherSearch() {
      this.type == 341 ? this.getDonateList(341) : this.getDonateList(342);
    },
    // handleTab(index) {
    //   this.title = "";
    //   this.activeIndex = index;
    //   index == 0
    //     ? this.getdonateMsg()
    //     : index == 1
    //     ? this.getDonateList(341)
    //     : this.getDonateList(342);
    // },
    getdonateMsg() {
      apiUrls
        .donateMsg({
          pageNumber: this.page,
          pageSize: this.size,
          donateName: this.donateName,
          minAmount: this.minAmount,
          maxAmount: this.maxAmount,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then((res) => {
          this.tableData = res.results.list;
          this.total = res?.results?.pager?.totalElements?? 0;
        });
    },
    getDonateList(type) {
      this.type = type;
      apiUrls
        .otherDonate({
          nodeId: type,
          pageNumber: this.page,
          pageSize: this.size,
          searchKeyword: this.title,
        })
        .then((res) => {
          this.listData = res.results.data;
          this.total = res.results.pager.totalElements;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.baseCard  {
  width: 944px;
  box-shadow: none;
}
.donateInfo {
  margin: 0px 0px;
  /deep/ .el-form-item__content {
    display: flex;
    justify-content: space-between;
  }
  .donateTop {
    display: flex;
    justify-content: space-between;
    .donateForm {
      width: 40%;
      padding: 0px 3%;
    }
    .donateIntro {
      width: 50%;
      background: #ffffff;
      border: 1px solid #ff7b7f;
      border-radius: 8px;
      padding: 10px 20px;
    }
  }
  .donateBottom {
    /deep/.el-table th,
    .el-table tr {
      background-color: #f4e2c8;
      color: #ae6d23;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
