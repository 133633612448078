<template>
   <!-- 审计报告公示内容 -->
  <div class="auditreport">
      <!-- 审计报告公示内容 -->
      <Newslist :list="zxauditreportlist" :nodename="'xxgk_zxsjbggs'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
  name: 'auditreport',
   props: {
    zxauditreportlist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  methods : {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.auditreport {
  width: 908px;
  padding: 39px 40px 41px 40px;
}
</style>
