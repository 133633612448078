<template>
<!-- 冠名医疗机构内容 -->
  <div class="medicalcare">
    <!-- 冠名医疗机构内容 -->
    <Newslist :list="medicallist" :nodename="'xxgk_gmyljg'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
   props: {
    medicallist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  name: 'Medicalcare',
  data () {
    return { 
    }
  },
  methods: {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.medicalcare {
    width: 908px;
    padding: 39px 40px 41px 40px;
}

</style>
