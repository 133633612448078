
<template>
<!-- 公告通知 -->
  <div class="notice qqq">
    <Newslist :list="noticelist" :nodename="'zjsygs'" @childByValue="childByValue"></Newslist>
  </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
  props: {
    noticelist: {
      type: Array,
      default: () =>[]
    }
  },
  components: {
    Newslist
  },
  name: 'Notice',
  data () {
    return { 
    }
  },
  methods: {
    childByValue (childByValue,nodename) {
      this.$emit('childByValue', childByValue,nodename)
    }
  }
}
</script>

<style lang="less" scoped>
.notice {
    width: 908px;
    padding: 39px 40px 41px 40px;
}
</style>
