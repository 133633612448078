<template>
  <div class="infotabcontent">
    <Notice v-if="title === '通知公告'" :noticelist="noticelist" @childByValue="childByValue"></Notice>
    <Donation v-else-if="title === '捐款公示'"></Donation>
    <Benevolence v-else-if="title === '捐物公示'"  :benevolencelist="benevolist" @childByValue="childByValue"></Benevolence>
    <Capital v-else-if="title === '捐赠使用公示'" :noticelist="zjList" @childByValue="childByValue"></Capital>
    <Personhelp v-else-if="title === '人道救助公示'" :persionlist="personhelplist" @childByValue="childByValue"></Personhelp>
    <Porjectopen v-else-if="title === '项目公示'" :projectopen="projectlist" @childByValue="childByValue"></Porjectopen>
    <Auditreport v-else-if="title === '年度审计报告公示'" :auditreportlist="sjlist" @childByValue="childByValue"></Auditreport>
	<zxsjbggs v-else-if="title === '专项审计报告公示'" :zxauditreportlist="zxsjlist" @childByValue="childByValue"></zxsjbggs>
    <Finance v-else-if="title === '预决算公示'" :fiancelist="finacelist" @childByValue="childByValue"></Finance>
    <Develop v-else-if="title === '规划与年鉴'" :developlist="developlist" @childByValue="childByValue"></Develop>
    <!-- <Work v-else-if="title === '工作报告'" :worklist="worklist" @childByValue="childByValue"></Work> -->
    <Medicalcare v-else-if="title === '冠名医疗机构'" :medicallist="medicallist" @childByValue="childByValue"></Medicalcare>
	<OtherDonate v-else :otherdonationlist="otherlist" @childByValue="childByValue"></OtherDonate >
  </div>
</template>

<script>
import Medicalcare from './medicalcare'
import Work from './work'
import Develop from './develop'
import Auditreport from './auditreport'
import zxsjbggs from './zxsjbggs'
import Finance from './finance'
import Porjectopen from './projectopen'
import Personhelp from './personhelp'
import Benevolence from './benevolence'
import Notice from './notice'
import Capital from './capital'
import Donation from './donation'
import OtherDonate from './otherdonation.vue'
import apiUrls from '@api'
export default {
  components : {
    Notice,
    Capital,
    Donation,
    Benevolence,
    Personhelp,
    Porjectopen,
    Auditreport,
	zxsjbggs,
    Finance,
    Develop,
    Work,
    Medicalcare,
	OtherDonate
  },
  name: 'Infotabcontent',
  props: {
      title : {
          type: String,
          default: ""
      }
  },
  data () {
      return {
        noticelist: [],
        benevolist:[],
        personhelplist: [],
        projectlist: [],
        sjlist: [],
		zxsjlist: [],
        finacelist: [],
        developlist: [],
        worklist: [],
        otherlist:[],
        zjList:[],
      }
  },
  mounted () {
    this.getnoticelist()
  },
  methods : {
    getnoticelist () {
      apiUrls.getinfoopen({node: 'tzgg'}).then(res=>{
        this.noticelist= res.results.data
      })
      apiUrls.getinfoopen({node: 'xxgk_jwgs'}).then(res=>{
      this.benevolist= res.results.data
      })
      apiUrls.getinfoopen({node: 'xxgk_rdjzgs'}).then(res=>{
      this.personhelplist= res.results.data
      })
      apiUrls.getinfoopen({node: 'xxgk_xmgs'}).then(res=>{
      this.projectlist= res.results.data
      })
      apiUrls.getinfoopen({node: 'xxgk_sjbggs'}).then(res=>{
      this.sjlist= res.results.data
      })
	  apiUrls.getinfoopen({node: 'xxgk_zxsjbggs'}).then(res=>{
	  this.zxsjlist= res.results.data
	  })
      apiUrls.getinfoopen({node: 'xxgk_cwgs'}).then(res=>{
      this.finacelist= res.results.data
      })
      apiUrls.getinfoopen({node: 'ghybg'}).then(res=>{
      this.developlist= res.results.data
      })
      apiUrls.getinfoopen({node: 'zjsygs'}).then(res=>{
      this.zjList= res.results.data
      })
      apiUrls.getinfoopen({node: 'xxgk_gmyljg'}).then(res=>{
      this.medicallist= res.results.data
      })
      apiUrls.getinfoopen({node: 'xxgk_qtjxxx'}).then(res=>{
      this.otherlist= res.results.data
      })
    },
    childByValue (childByValue,nodename) {
      if (nodename === "tzgg") {
        this.noticelist= childByValue
      }else if (nodename === "xxgk_jwgs") {
        this.benevolist = childByValue
      }else if (nodename === "xxgk_rdjzgs") {
        this.personhelplist = childByValue
      }else if (nodename === "xxgk_xmgs") {
        this.projectlist = childByValue
      }else if (nodename === "xxgk_sjbggs"){
        this.sjlist = childByValue
      }else if (nodename === "xxgk_cwgs") {
        this.finacelist = childByValue
      }else if (nodename === "ghybg") {
       this.developlist = childByValue
      }else if (nodename === "zjsygs") {
        this.zjList = childByValue
      }else if (nodename === "xxgk_qtjxxx") {
        this.otherlist = childByValue
      }else {
       this.medicallist= childByValue
      }
      
    }
  }
}
</script>

<style lang="less" scoped>
.infotabcontent {
   width: 988px;
   min-height: 300px;
   background-color: #ffffff;
   box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
}
</style>
